@import "~foundation-sites/assets/foundation";

body {
    background-color: rgb(250,250,250);
}

html,
body,
#root {
    height: 100%;
}
.grid-x {
    height: 100%;
}

.logo {
    max-width: 500px;
    height: auto;
    margin-bottom: $global-margin*3;
}